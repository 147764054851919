.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  touch-action: none;
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.input-container {
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.serif-input {
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  outline: none;
  font-family: 'Georgia', serif;
  margin-right: 10px;
}

.serif-input::placeholder {
  color: #999;
  font-style: italic;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.canvas-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.canvas {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  font-family: 'Georgia', serif;
  position: relative;
}

.card.selected {
  border: 2px solid #007bff;
}

.input-card {
  display: flex;
  flex-direction: column;
}

.serif-input {
  width: 100%;
  border: none;
  background: transparent;
  font-size: 16px;
  outline: none;
  font-family: 'Georgia', serif;
  margin-bottom: 10px;
}

.serif-input::placeholder {
  color: #999;
  font-style: italic;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  align-self: flex-end;
}

button:hover {
  background-color: #0056b3;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #dc3545;
}

.delete-button:hover {
  background-color: #c82333;
}

.debug-info {
  position: fixed;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
}
